<template>
  <div id="navigation-pane" class="v-flex gap-lg">
    <div class="title uppercase clickable" @click="onTitleClick">Lorenzo Farinelli</div>
    <nav-section
      v-for="(section, sectionId) in data"
      :key="section"
      :sectionTitle="section.title"
      :links="section.projects"
      :initialExpanded="true"
      :showIcon="true"
      @link-click="onLinkClick"
      :sectionId="sectionId"
    />
    <div class="flex-filler" />
    <nav-section sectionTitle="About/contact" section-id="about" />
  </div>
</template>

<style scoped lang="scss">
#navigation-pane {
  border-right: 1px solid var(--fg-primary);
}
</style>

<script>
import NavSection from "@/components/NavSection.vue";
import { mapState } from "vuex";
export default {
  name: "NavigationPane",
  components: {
    NavSection,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      data: (state) => state.data,
    }),
  },
  methods: {
    onLinkClick(link) {
      this.$emit("link-click", link);
    },
    onTitleClick() {
      this.$router.push("/");
    },
  },
};
</script>
