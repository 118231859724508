<template>
  <div class="nav-section v-flex gap-sm">
    <div class="h-flex gap-sm clickable" @click="onHeaderClick">
      <div class="nav-heading flex-filler">{{ sectionTitle }}</div>
      <div v-if="showIcon && links" class="nav-heading">{{ icon }}</div>
    </div>
    <div v-if="links && expanded" class="v-flex gap-xs">
      <div
        class="nav-link"
        v-for="link in links"
        :key="link.title"
        @click="() => onLinkClick(link)"
      >
        {{ link.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavSection",
  props: {
    sectionTitle: {
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      default: null,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    initialExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Reassign to copy, so we don't modify the prop
      expanded: this.initialExpanded ? true : false,
    };
  },
  computed: {
    icon() {
      return this.expanded ? "–" : "+";
    },
  },
  methods: {
    onHeaderClick() {
      this.expanded = !this.expanded;
    },
    onLinkClick(link) {
      link = { ...link, section: this.sectionId };
      this.$emit("link-click", link);
    },
  },
};
</script>
