<template>
  <div
    id="home"
    class="v-flex gap-md full-height no-overflown align-center justify-center"
  >
    <div>Work in progress, please be patient :)</div>
    <img
      class="hero-img"
      src="@/assets/berlin.png"
      title="Berlin - Akademie der Kunste"
    />
    <div class="caption">Berlin - Akademie der Kunste</div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
};
</script>
