import { createStore } from "vuex";
// Import @/assets/data.json in the state
import data from "@/assets/data.json";

export default createStore({
  state: {
    data,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
