<template>
  <div id="main" class="page no-overflown full-height">
    <navigation-pane class="p-xl" @link-click="onLinkClicks" />
    <router-view class="overflown-y p-xl full-width subpage" />
  </div>
</template>

<style scoped>
#main {
  display: grid;
  grid-template-columns: 1fr 3fr;
  background-size: cover;
}
</style>

<script>
import NavigationPane from "@/components/NavigationPane.vue";
export default {
  name: "LorenzoFarinelli",
  components: {
    NavigationPane,
  },
  methods: {
    onLinkClicks(link) {
      this.$router.push(`/${link.section}/${link.id}`);
    },
  },
};
</script>
