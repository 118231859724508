import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/assets/styles/fonts.scss";
import "@/assets/styles/variables.scss";
import "@/assets/styles/text.scss";
import "@/assets/styles/layout.scss";
import "@/assets/styles/style.scss";

const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");
