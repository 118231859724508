import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Lorenzo Farinelli",
    },
  },
  {
    path: "/:sectionId/:projectId",
    name: "project",
    component: () => import("../views/ProjectView.vue"),
  },
  {
    path: "/404",
    name: "not-found",
    component: () => import("../views/NotFoundView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "not-found" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
